import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService, Court } from 'src/app/services/common.service';
import { ScheduledEvent } from '../user-dashboard/user-dashboard.component';
import { BookedEvent, BookResource } from '../user-edit/user-edit.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit, AfterViewInit {

  reservationDate: Date = new Date();
  form: FormGroup;
  events: BookedEvent[];
  resources: BookResource[];
  loading = false;
  isAdmin: boolean;

  constructor(private commonService: CommonService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      DateStart: [new Date(), [Validators.required]],
    });

    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.isAdmin = this.commonService.currentAdminUser ? true : false;
    this.commonService.getDateEventsRaw(this.form.get('DateStart').value).subscribe((events: BookedEvent[]) => {
      this.events = events.filter(e => e.ResourceName.indexOf('Tennis') > -1);

      this.commonService.getResources().subscribe((res: BookResource[]) => {
        this.resources = res
          .filter(r => r.Full_Name && r.Full_Name.indexOf('Tennis') > -1)
          .map(c => ({
            ...c,
            Full_Name: c.Groups.indexOf(',10,') > -1 ? c.Full_Name.replace('1', '5').replace('2', '6') : c.Full_Name,
          }))
          .sort((a, b) => a.Full_Name.localeCompare(b.Full_Name));

        this.loading = false;
      });
    });
  }

  ngAfterViewInit() {
    //
  }

  /*onChange(event): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });

    this.loadData();
  }*/

  /*ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }*/

  dateAdd(v: number): void {
    const d = new Date(this.form.get('DateStart').value);
    d.setDate(d.getDate() + v);

    this.form.patchValue({
      DateStart: d,
    });
  }

  changeDate(event): void {
    this.loadData();
  }
}
