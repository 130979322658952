import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgZone } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { BookedEvent, BookResource } from '../user-edit/user-edit.component';
import { DatePipe } from '@angular/common';
import { DateobjPipe } from 'src/app/components/admin-dashboard/admin-dashboard.component';

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GraphComponent implements OnInit {

  private chart: am4charts.XYChart;

  @Input()
  events: BookedEvent[];

  @Input()
  resources: BookResource[];

  @Input()
  date: Date;

  @Input()
  userView: boolean;

  constructor(private zone: NgZone, private datePipe: DatePipe, private dateobjPipe: DateobjPipe) { }

  ngOnInit(): void {
    this.showGraph();
  }

  showGraph() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('chartdiv', am4charts.XYChart);

      chart.paddingRight = 20;
      chart.paddingLeft = 0;
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm';

      const colorSet = new am4core.ColorSet();

      const emptyCourts = [];
      this.resources.forEach(court => {
        let add = '';

        if (court.Full_Name.indexOf('5') > -1 || court.Full_Name.indexOf('6') > -1) {
          add = '\n Indoor (Halle)';
        }

        emptyCourts.push({ name: court.Full_Name + add });
      });

      const bookedCourts = [];
      this.events.forEach(event => {
        let add = '';

        if (event.ResourceName.indexOf('5') > -1 || event.ResourceName.indexOf('6') > -1) {
          add = '\n Indoor (Halle)';
        }

        bookedCourts.push({
          name: event.ResourceName.split('|')[0] + add,
          fromDate: event.DateStart.toString().replace(/:00$/, ''),
          toDate: event.DateEnd.toString().replace(/:00$/, ''),
          color: this.getColor(event.Title),
          bgcolor: event.Title.indexOf('CG ') > -1 ? am4core.color('#999999') : this.getColor(event.Title),
          scolor: am4core.color('#666666'),
          hrFrom: this.datePipe.transform(this.dateobjPipe.transform(event.DateStart), 'HH:mm'),
          hrTo: this.datePipe.transform(this.dateobjPipe.transform(event.DateEnd), 'HH:mm'),
          vs: `${event.Title}`
        });
      });

      chart.data = [
        ...emptyCourts,
        ...bookedCourts
      ];

      console.log(chart.data);

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm';
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 30, timeUnit: 'minute' };

      const date = new Date(this.date);
      const curMonth = date.getMonth();
      const curYear = date.getFullYear();
      const curDay = date.getDate();

      dateAxis.min = new Date(curYear, curMonth, curDay, 8, 0, 0, 0).getTime();
      dateAxis.max = new Date(curYear, curMonth, curDay, 23, 0, 0, 0).getTime();
      dateAxis.strictMinMax = true;
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.renderer.labels.template.location = 0.0001;

      dateAxis.gridIntervals.setAll([
        { timeUnit: 'hour', count: 1 },
      ]);

      const series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(80);
      series1.columns.template.height = am4core.percent(60);

      if (this.userView) {
        series1.columns.template.tooltipText = `{openDateX.formatDate('HH:mm')} - {dateX.formatDate('HH:mm')}[/]`;
      }
      else {
        series1.columns.template.tooltipText = `{openDateX.formatDate('HH:mm')} - {dateX.formatDate('HH:mm')}[/]
        {vs}`;
      }

      series1.dataFields.openDateX = 'fromDate';
      series1.dataFields.dateX = 'toDate';
      series1.dataFields.categoryY = 'name';
      series1.columns.template.propertyFields.fill = 'color'; // get color from data
      series1.columns.template.propertyFields.stroke = 'scolor';
      series1.columns.template.strokeOpacity = 1;

      chart.logo.disabled = true;

      this.chart = chart;
    });
  }

  private getColor(title: string): am4core.Color {
    if (this.userView) {
      return am4core.color('lightgray');
    }

    const parts = title.split(' ');
    if (parts[0] === 'RR') {
      return am4core.color('#7FFF00');
    }
    else if (parts[0] === 'TL') {
      return am4core.color('#0080FF');
    }
    else if (parts[0] === 'TC') {
      return am4core.color('#FFFF00');
    }
    else if (parts[0] === 'CG') {
      return am4core.color('#f5f5f5');
    }

    return am4core.color('#00ffff');
  }

}
