  <div nz-row style="margin-top: 10px">
      <div nz-col [nzSpan]="12">
          <h2>Users</h2>
      </div>
      <div nz-col [nzSpan]="12" style="text-align: right;">
        <button nz-button nzType="dashed" (click)="addUser()">
            <i nz-icon [nzType]="'plus'" [nzTheme]="'outline'"></i> Add User
        </button>
    </div>
  </div>
  
  <br/>
  
  <nz-skeleton [nzActive]="true" *ngIf="loading"></nz-skeleton>
  
  <nz-table #userTable [nzData]="users" [nzPageSize]="10" *ngIf="!loading">
      <thead>
        <tr>
          <th
            *ngFor="let column of columns"
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of userTable.data">
          <td>
            {{data.name}}<br/>
            <small>{{data.email}}</small>
          </td>
          <td>{{data.roles}}</td>
          <td>{{data.updated_at}}</td>
          <td>{{data.bookings}}</td>
          <td>
            <a [routerLink]="['/admin-edit', data.id]">Change</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="Are you sure delete this user?" nzPopconfirmPlacement="bottom" (nzOnCancel)="cancel()" (nzOnConfirm)="deleteUser(data)">Delete</a>
          </td>
        </tr>
      </tbody>
  </nz-table>
  
