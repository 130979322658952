import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from './services/jwt-helper.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  username: string;
  email: string;
  id: number;
  isadmin: number;
  curDate = new Date();

  constructor(
    private jwtHelper: JwtHelperService, private authService: AuthService, private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.email = this.jwtHelper.email();

    this.commonService.currentUser.subscribe(username => this.username = username);
    this.commonService.currentUserId.subscribe(userId => this.id = userId);
    this.commonService.currentAdmin.subscribe(isadmin => this.isadmin = isadmin);

    this.commonService.updateUserId(this.jwtHelper.id());
    this.commonService.updateUsername(this.jwtHelper.name());
    this.commonService.updateAdmin(this.jwtHelper.isadmin());
    this.commonService.updateLogin(this.jwtHelper.email());
    this.commonService.updateRoles(this.jwtHelper.roles());
  }

  logout(): void {
    this.authService.logout()
    .subscribe(() => {
      this.clearUserData();
    },
    err => {
      this.clearUserData();
    });
  }

  private clearUserData(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('greet');
    this.commonService.updateUserId(0);
    this.commonService.updateUsername('');
    this.commonService.updateAdmin(0);
    this.router.navigate(['/login']);
  }
}
