import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy,
        Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { Observable, Observer } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { DateobjPipe} from 'src/app/components/admin-dashboard/admin-dashboard.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export interface BookedEvent {
  ID: number;
  Owner: string;
  Title: string;
  SubText: string;
  DateStart: string;
  DateEnd: string;
  Colour: number;
  ResourceName: string;
  user_id?: number;
  AlarmDate?: string;
}

export interface BookResource {
  ID: number;
  Full_Name: string;
  Display_Name: string;
  Colour: string;
  ColourBack: string;
  EventColourText: string;
  Groups: string;
  Status: string;
}

@Pipe({name: 'role'})
export class RolePipe implements PipeTransform {
  transform(role: string, currentRoles: string): boolean {
    return !(currentRoles.indexOf(role) > -1);
  }
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class UserEditComponent implements OnInit {
  event: BookedEvent;
  resources: BookResource[];
  form: FormGroup;
  loading = true;
  rawLoading = true;
  eventsLoaded = false;
  events: BookedEvent[];
  rawEvents: BookedEvent[] = [];
  currentUserId: number;
  currentUserName: string;
  currentLogin: string;
  currentRoles: string;
  reservationId: number;
  currentUserAdmin: number;
  freeTimes: any[] = [];
  freeTimesLoading = false;
  loadedResourceName: string;
  loadedDate: string;
  loadedInterval: number;
  loadedTime: string;
  disableDateDec = false;
  blockedCourtId: number;
  disable15hResId: number[];
  courtIsBlocked = false;

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }

  constructor(
    private router: Router, private route: ActivatedRoute, private fb: FormBuilder,
    private commonService: CommonService, private datePipe: DatePipe, private notification: NzNotificationService,
    private dateObjPipe: DateobjPipe
  ) {
    this.disable15hResId = [];
  }

  ngOnInit(): void {
    this.commonService.currentUserId.subscribe(userId => this.currentUserId = userId);
    this.commonService.currentUser.subscribe(userName => this.currentUserName = userName);
    this.commonService.currentLogin.subscribe(login => this.currentLogin = login);
    this.commonService.currentRoles.subscribe(roles => this.currentRoles = roles);
    this.commonService.currentAdmin.subscribe(userAdmin => this.currentUserAdmin = userAdmin);

    this.route.paramMap.subscribe(params => {
      this.reservationId = Number(params.get('reservationId'));

      this.commonService.getResources().subscribe((res: BookResource[]) => {
        this.resources = res
          .filter(r => r.Full_Name && r.Full_Name.indexOf('Tennis') > -1)
          .map(c => ({
            ...c,
            Display_Name: c.Groups.indexOf(',10,') > -1 ? c.Full_Name.replace('1', '5').replace('2', '6') : c.Full_Name,
          }))
          .map(c => {
            if (c.Full_Name.indexOf('5') > -1) {
              this.blockedCourtId = c.ID;
              this.disable15hResId.push(c.ID);
            }
            else if (c.Full_Name.indexOf('6') > -1) {
              this.disable15hResId.push(c.ID);
            }

            return c;
          })
          .sort((a, b) => a.Display_Name.localeCompare(b.Display_Name));

        this.form = this.fb.group({
          DateStart: [new Date(), [Validators.required]],
          ResourceName: ['', [Validators.required]],
          Title: [`${this.currentUserName} vs `, [Validators.required]],
          Prefix: ['', [Validators.required]],
          Subtext: [this.currentLogin],
          // StartHour: ['', [Validators.required]],
          // StartMin: ['', [Validators.required]],
          Duration: ['60', [Validators.required]],
          SelectedTime: ['', [Validators.required]],
        });

        if (this.reservationId) {
          this.commonService.getEvent(this.reservationId).subscribe((event: BookedEvent) => {
            const parts = event.Title.split(' ');
            const type = parts.shift();
            const title = parts.join(' ');

            let startTime: number | string = new Date(this.dateObjPipe.transform(event.DateStart)).getHours();
            if (startTime < 10) {
              startTime = '0' + startTime;
            }

            let startTimeMin: number | string = new Date(this.dateObjPipe.transform(event.DateStart)).getMinutes();
            if (startTimeMin < 10) {
              startTimeMin = '0' + startTimeMin;
            }

            this.form = this.fb.group({
              DateStart: [new Date(this.dateObjPipe.transform(event.DateStart)), [Validators.required]],
              ResourceName: [Number(event.Owner.replace(/,/g, '')), [Validators.required]],
              Title: [title, [Validators.required]],
              Prefix: [type, [Validators.required]],
              Subtext: [event.SubText],
              Duration: [((+new Date(event.DateEnd) - +new Date(event.DateStart)) / 60000).toString(), [Validators.required]],
              SelectedTime: [event.DateStart.split(' ')[1], [Validators.required]],
            });

            this.form.get('Duration').enable();

            this.loadedTime = this.form.get('SelectedTime').value;
            this.loadedDate = this.datePipe.transform(this.dateObjPipe.transform(this.form.get('DateStart').value), 'yyyy-MM-dd');
            this.loadedInterval = Number(this.form.get('Duration').value);
            this.loadedResourceName = this.form.get('ResourceName').value;

            this.changeDuration(this.form.get('Duration').value);

            this.disableDateDec = differenceInCalendarDays(this.form.get('DateStart').value, new Date()) < 1;
          });
        }
        else {
          this.form.get('Duration').disable();
          this.disableDateDec = differenceInCalendarDays(this.form.get('DateStart').value, new Date()) < 1;
        }

        this.changeDate(new Date());
        this.loading = false;
      });
    });
  }

  addReservation(): void {
    const dateStart = new Date(this.form.get('DateStart').value);
    let day: string|number = dateStart.getDate();
    let month: string|number = dateStart.getMonth() + 1;
    const year = dateStart.getFullYear();

    const [ hrStart, minStart ] = this.form.get('SelectedTime').value.split(':');
    const duration = this.form.get('Duration').value;

    let hrEnd: string|number = Number(hrStart) + 1;
    let minEnd = minStart;

    let hrAlarm = Number(hrStart);
    let minAlarm = Number(minStart) - 5;

    if (minAlarm < 0) {
      minAlarm = 55;
      hrAlarm--;
    }

    if (duration === '120') {
      hrEnd++;
    }
    else if (duration === '90') {
      if (minEnd === '00') {
        minEnd = '30';
      }
      else if (minEnd === '15') {
        minEnd = '45';
      }
      else if (minEnd === '30') {
        minEnd = '00';
        hrEnd++;
      }
      else if (minEnd === '45') {
        minEnd = '15';
        hrEnd++;
      }
    }

    const leadZero = (val) => {
      return val < 10 ? `0${val}` : val.toString();
    };

    day = leadZero(day);
    month = leadZero(month);
    hrEnd = leadZero(hrEnd);
    hrAlarm = leadZero(hrAlarm);

    const resName = this.resources.filter(r => r.ID === this.form.get('ResourceName').value)[0].Full_Name;

    let color = 16744448;
    if (this.form.get('Prefix').value === 'TC') {
      color = 65535;
    }
    else if (this.form.get('Prefix').value === 'CG') {
      color = 16777215;
    }
    else if (this.form.get('Prefix').value === 'RR') {
      color = 65407;
    }

    this.event = {
      ID: this.reservationId ? this.reservationId : 0,
      Owner: `,${this.form.get('ResourceName').value},`,
      Title: this.form.get('Prefix').value + ' ' + this.form.get('Title').value,
      SubText: this.currentUserAdmin ? this.form.get('Subtext').value : this.currentLogin,
      DateStart: `${year}-${month}-${day} ${hrStart}:${minStart}:00`,
      DateEnd: `${year}-${month}-${day} ${hrEnd}:${minEnd}:00`,
      AlarmDate: `${year}-${month}-${day} ${hrAlarm}:${minAlarm}:00`,
      ResourceName: `${resName}|,${this.form.get('ResourceName').value},|`,
      Colour: color,
      user_id: this.currentUserId,
    };

    if (this.reservationId) {
      this.commonService.updateEvent(this.event).subscribe(e => {
        this.redirectToDashboard();
      },
      err => {
        this.notification.create(
          'warning',
          err.statusText,
          ''
        );
      });
    }
    else {
      this.commonService.saveEvent(this.event).subscribe(e => {
        this.redirectToDashboard();
      },
      err => {
        this.notification.create(
          'warning',
          err.statusText,
          ''
        );
      });
    }
  }

  cancelReservation(): void {
    this.redirectToDashboard();
  }

  private redirectToDashboard(): void {
    if (this.currentUserAdmin) {
      this.router.navigate(['/admin-dashboard']);
    }
    else {
      this.router.navigate(['/user-dashboard']);
    }
  }

  changeCourt(event): void {
    if (Number(this.form.get('ResourceName').value) === Number(this.blockedCourtId)) {
      this.courtIsBlocked = true;
    }
    else {
      this.courtIsBlocked = false;
    }

    if (!this.form.get('ResourceName').value) {
      this.form.get('Duration').disable();
    }

    if (!event) {
      return;
    }

    this.form.patchValue({ 
      Duration: (Number(this.disable15hResId[0]) === Number(this.form.get('ResourceName').value)
                || Number(this.disable15hResId[1]) === Number(this.form.get('ResourceName').value))
        ? '60' : this.form.get('Duration').value
    });
  }

  changeDate(event): void {
    this.freeTimes.length = 0;
    this.rawEvents.length = 0;

    this.form.patchValue({
      Owner: '',
      // ResourceName: '',
      Duration: '60',
      DateEnd: '',
      SelectedTime: '',
    });

    this.rawLoading = true;
    this.commonService.getDateEventsRaw(this.form.get('DateStart').value).subscribe((events: BookedEvent[]) => {
      this.rawEvents = events.filter(e => e.ResourceName.indexOf('Tennis') > -1);

      this.rawLoading = false;
    });
  }

  changeDuration(event, addValue = false): void {
    if (!this.form.get('ResourceName').value) {
      return;
    }

    this.form.get('Duration').enable();

    this.freeTimesLoading = true;
    this.freeTimes.length = 0;
    this.commonService.getFreeTimes(new Date(this.form.get('DateStart').value), this.form.get('ResourceName').value, event - 1, this.reservationId)
      .subscribe((data: any[]) => {
        console.log('d', data);

        this.freeTimes = data.filter(t => {
          return ((Number(this.form.get('ResourceName').value) !== Number(this.disable15hResId[0])
                  && Number(this.form.get('ResourceName').value) !== Number(this.disable15hResId[1]))
                  || t.start.indexOf(':30') === -1);
        });
        this.freeTimesLoading = false;

        if (
            this.datePipe.transform(this.dateObjPipe.transform(this.form.get('DateStart').value), 'yyyy-MM-dd') === this.loadedDate
            && Number(this.form.get('Duration').value) === this.loadedInterval
            && this.form.get('ResourceName').value === this.loadedResourceName
          )
        {
          // this.freeTimes.push({ start: this.loadedTime });

          this.freeTimes.sort((a, b) => {
            const aHr = Number(a.start.split(':')[0]);
            const bHr = Number(b.start.split(':')[0]);

            return aHr - bHr;
          });
        }

        console.log(this.freeTimes);
      });
  }

  dateAdd(v: number): void {
    const d = new Date(this.form.get('DateStart').value);
    d.setDate(d.getDate() + v);

    this.disableDateDec = differenceInCalendarDays(d, new Date()) < 1;

    this.form.patchValue({
      DateStart: d,
    });
  }
}
