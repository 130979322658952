import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../components/admin-users/admin-users.component';
import { DatePipe } from '@angular/common';
import { Subject, BehaviorSubject } from 'rxjs';
import { ScheduledEvent } from '../components/user-dashboard/user-dashboard.component';
import { BookedEvent } from '../components/user-edit/user-edit.component';

export interface Court {
  id: number;
  name: string;
  group_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  userId: number;
  userName: string;
  currentAdminUser: number;
  roles: string;
  login: string;

  currentUser = new BehaviorSubject<string>('');
  currentRoles = new BehaviorSubject<string>('');
  currentLogin = new BehaviorSubject<string>('');
  currentUserId = new BehaviorSubject<number>(0);
  currentAdmin = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  updateUsername(username: string) {
    this.currentUser.next(username);
    this.userName = username;
  }

  updateUserId(userId: number) {
    this.currentUserId.next(userId);
    this.userId = userId;
  }

  updateAdmin(admin: number) {
    this.currentAdmin.next(admin);
    this.currentAdminUser = admin;
  }

  updateRoles(roles: string) {
    this.currentRoles.next(roles);
    this.roles = roles;
  }

  updateLogin(login: string) {
    this.currentLogin.next(login);
    this.login = login;
  }

  getResources() {
    return this.http.get(`${environment.apiUrl}/resources`);
  }

  getFreeTimes(date: Date, resourceId: number, interval: number, userEventId: number) {
    return this.http.get(`${environment.apiUrl}/events/getFreeTimes/${this.datePipe.transform(date, 'yyyy-MM-dd')}/${resourceId}/${interval}/${userEventId}`);
  }

  getDateEvents(date: Date) {
    return this.http.get(`${environment.apiUrl}/events/getByDate/${this.datePipe.transform(date, 'yyyy-MM-dd')}`);
  }

  getDateEventsRaw(date: Date) {
    return this.http.get(`${environment.apiUrl}/events/getByDateRaw/${this.datePipe.transform(date, 'yyyy-MM-dd')}`);
  }

  getTodayEvents() {
    return this.http.get(`${environment.apiUrl}/events/getTodayEvents/${this.datePipe.transform(Date.now(), 'yyyy-MM-dd')}`);
  }

  getTomorrowEvents() {
    return this.http.get(`${environment.apiUrl}/events/getByDate/${this.datePipe.transform(Date.now() + 86400000, 'yyyy-MM-dd')}`);
  }

  getUserUpcomingEvents() {
    return this.http.get(`${environment.apiUrl}/events/getByUser/${this.login}`);
  }

  getUserHistoryEvents() {
    return this.http.get(`${environment.apiUrl}/events/getHistoryByUser/${this.login}`);
  }

  getHistory() {
    return this.http.get(`${environment.apiUrl}/events/getHistory/100`);
  }

  getUsers() {
    return this.http.get(`${environment.apiUrl}/users`);
  }

  getUser(userId: number) {
    return this.http.get(`${environment.apiUrl}/users/${userId}`);
  }

  saveUser(user: User) {
    return this.http.post(`${environment.apiUrl}/users/store`, user);
  }

  updateUser(user: User) {
    return this.http.post(`${environment.apiUrl}/users/update`, user);
  }

  deleteUser(user: User) {
    return this.http.post(`${environment.apiUrl}/users/destroy/${user.id}`, null);
  }

  deleteEvent(event: BookedEvent) {
    return this.http.post(`${environment.apiUrl}/events/destroy/${event.ID}`, null);
  }

  getEvent(eventId: number) {
    return this.http.get(`${environment.apiUrl}/events/${eventId}`);
  }

  saveEvent(event: BookedEvent) {
    return this.http.post(`${environment.apiUrl}/events/store`, event);
  }

  updateEvent(event: BookedEvent) {
    return this.http.post(`${environment.apiUrl}/events/update`, event);
  }
}
