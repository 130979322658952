import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonService } from 'src/app/services/common.service';
import { BookedEvent } from '../user-edit/user-edit.component';

@Pipe({name: 'dateobj'})
export class DateobjPipe implements PipeTransform {
  transform(date: string): string {
    if (typeof date === 'string') {
      return date.replace(' ', 'T');
    }
    return date;
  }
}

@Pipe({name: 'reslist'})
export class ReslistPipe implements PipeTransform {
  transform(resName: string): string {
    const parts = resName.split('|');
    const courts = [];

    for (let k = 0; k < parts.length - 1; k += 2) {
      if (parts[k].indexOf('Tennis') > -1) {
        if (parts[k + 1].indexOf(',10,') > -1) {
          courts.push(parts[k].replace('1', '5').replace('2', '6'));
        }
        else {
          courts.push(parts[k]);
        }
      }
    }

    return courts.map(e => e.replace(/, /, '')).sort((a, b) => a.localeCompare(b)).join(', ');
  }
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  currentUsername: string;

  todayBookingsLoading = false;
  tomorrowBookingsLoading = false;
  historyLoading = false;
  showGreet = false;

  todayBookings: BookedEvent[];
  tomorrowBookings: BookedEvent[];
  historyBookings: BookedEvent[];

  constructor(
    private router: Router, private nzMessageService: NzMessageService, private notification: NzNotificationService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (!localStorage.getItem('greet')) {
      localStorage.setItem('greet', '1');
      this.showGreet = true;
      setTimeout(() => this.showGreet = false, 5000);
    }

    this.commonService.currentUser.subscribe(username => this.currentUsername = username);

    this.loadData();
  }

  loadData(): void {
    this.todayBookingsLoading = true;
    this.commonService.getTodayEvents().subscribe((events: BookedEvent[]) => {
      this.todayBookings = events.filter(e => e.ResourceName.indexOf('Tennisplatz') > -1);
      this.todayBookingsLoading = false;
    });

    this.tomorrowBookingsLoading = true;
    this.commonService.getTomorrowEvents().subscribe((events: BookedEvent[]) => {
      this.tomorrowBookings = events.filter(e => e.ResourceName.indexOf('Tennisplatz') > -1);
      this.tomorrowBookingsLoading = false;
    });

    this.historyLoading = true;
    this.commonService.getHistory().subscribe((events: BookedEvent[]) => {
      this.historyBookings = events.filter(e => e.ResourceName.indexOf('Tennisplatz') > -1);
      this.historyLoading = false;
    });
  }

  deleteReservation(event: BookedEvent): void {
    this.commonService.deleteEvent(event).subscribe((resp) => {
      this.notification.create(
        'warning',
        'This reservation was deleted',
        ''
      );

      this.loadData();
    });
  }

  cancel(): void {
    //
  }

  addUser(): void {
    this.router.navigate(['/admin-users']);
  }
}
