<h2>{{ reservationId ? 'Reservation ändern' : 'Neue Reservation' }}</h2>

<form nz-form [formGroup]="form" *ngIf="!loading">
    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="date">Datum</nz-form-label>
        <nz-form-control [nzSpan]="20">
            <button (click)="dateAdd(-1)" nz-button nzType="default" [disabled]="disableDateDec">
                <i nz-icon nzType="left" nzTheme="outline"></i>
            </button>

            &nbsp;

            <nz-date-picker [nzDisabledDate]="disabledDate" formControlName="DateStart" (ngModelChange)="changeDate($event)"></nz-date-picker>

            &nbsp;

            <button (click)="dateAdd(1)" nz-button nzType="default">
                <i nz-icon nzType="right" nzTheme="outline"></i>
            </button>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="resource">Platz</nz-form-label>
        <nz-form-control [nzSpan]="6">
          <nz-select formControlName="ResourceName" (ngModelChange)="changeCourt($event)">
            <nz-option nzValue="" nzLabel="Platznummer auswählen"></nz-option>  
            <nz-option *ngFor="let res of resources" [nzValue]="res.ID" [nzLabel]="res.Display_Name"></nz-option>
          </nz-select>

          <nz-list nzBordered nzHeader="These times are already taken:" *ngIf="eventsLoaded && events.length > 0">
            <nz-list-item *ngFor="let item of events">
              <span nz-typography><mark>{{ item.DateStart | dateobj | date:'HH:mm' }} - {{ item.DateEnd | dateobj | date:'HH:mm' }}</mark></span>
            </nz-list-item>
          </nz-list>
        </nz-form-control>
    </nz-form-item>
    
    <nz-form-item *ngIf="courtIsBlocked">
        <nz-form-control [nzSpan]="18" [nzOffset]="3">
            <span style="color: red">
                Wegen möglichen Badmintonplatz-Reservationen, den Platz 5 erst dann buchen, wenn Platz 6 zur gewünschten Zeit bereits belegt ist.
            </span>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-control [nzSpan]="24">
            <app-graph *ngIf="!rawLoading" [userView]="!currentUserAdmin" [events]="rawEvents" [resources]="resources" [date]="form.get('DateStart').value"></app-graph>
            <nz-skeleton *ngIf="rawLoading" [nzActive]="true"></nz-skeleton>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="duration">Dauer</nz-form-label>
        <nz-form-control [nzSpan]="12">
            <nz-radio-group formControlName="Duration" nzButtonStyle="solid" (ngModelChange)="changeDuration($event)">
                <label nz-radio-button nzValue="60">1 hr</label>
                <label nz-radio-button nzValue="90" *ngIf="form.get('ResourceName').value != disable15hResId[0] && form.get('ResourceName').value != disable15hResId[1]">1.5 hr</label>
                <label nz-radio-button nzValue="120">2 hr</label>
            </nz-radio-group>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="date">Verfügbare Zeiten</nz-form-label>
        
        <nz-form-control [nzSpan]="12" *ngIf="freeTimesLoading">
            <nz-skeleton [nzActive]="true"></nz-skeleton>
        </nz-form-control>

        <nz-list *ngIf="freeTimes.length" nzBordered nzHeader="" style="max-height: 300px; overflow-y: auto">
            <nz-radio-group formControlName="SelectedTime">
                <nz-list-item *ngFor="let item of freeTimes">
                    <span nz-typography><label nz-radio [nzValue]="item.start"><mark>{{ item.start }}</mark></label></span>
                </nz-list-item>
            </nz-radio-group>
        </nz-list>

        <div style="padding-top: 6px" *ngIf="!freeTimes.length && form.get('ResourceName').value && form.get('DateStart').value && !freeTimesLoading">
            <strong>No timeslots available</strong>
        </div>
    </nz-form-item>
    
    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="title">{{ currentUserAdmin && reservationId ? 'Typ' : 'Typ'}}</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <nz-radio-group id="types" formControlName="Prefix" nzButtonStyle="solid">
                <label nz-radio-button nzValue="TL" [nzDisabled]="'Instructor' | role: currentRoles">Tennislehrer</label>
                <label nz-radio-button nzValue="TC" [nzDisabled]="'Club Member' | role: currentRoles">Clubmitglied mit Clubmitglied</label>
                <label nz-radio-button nzValue="CG" [nzDisabled]="'Club Guest' | role: currentRoles">Clubmitglied mit Gast</label>
                <label nz-radio-button nzValue="RR" [nzDisabled]="'Round Robin' | role: currentRoles">Round Robin</label>
            </nz-radio-group>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="3" nzFor="title">Text</nz-form-label>
        <nz-form-control [nzSpan]="12">
            <input [readonly]="0 && courtIsBlocked" nz-input formControlName="Title" placeHolder="Text" style="margin-top: 5px" />
            <small>
                Bitte Name(n) des Gegners resp. Mitspielers (bei Doppel) reinschreiben. (z.B. Meier Franz/Müller Hans vs. Muster Peter/Seiler Fritz)
            </small>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="currentUserAdmin && reservationId">
        <nz-form-label [nzSpan]="3" nzFor="subtext">User</nz-form-label>
        <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="Subtext" placeHolder="Username" readonly />
        </nz-form-control>
    </nz-form-item>

    <div nz-row>
        <div nz-col [nzSpan]="11" style="text-align: right;">
            <button nz-button nzType="primary" (click)="addReservation()" [disabled]="!form.valid">
                <i nz-icon [nzType]="'plus'" [nzTheme]="'outline'"></i> {{ reservationId ? 'Buchen' : 'Buchen' }}
            </button>
        </div>
        <div nz-col [nzSpan]="2">
        </div>
        <div nz-col [nzSpan]="11" style="text-align: left;">
            <button nz-button nzType="dashed" (click)="cancelReservation()">
                Abbrechen
            </button>
        </div>
    </div>
</form>