import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonService } from 'src/app/services/common.service';
import { NzTableSortOrder, NzTableSortFn, NzTableFilterList, NzTableFilterFn } from 'ng-zorro-antd/table';

export interface User {
  id: number;
  name: string;
  password: string;
  email: string;
  roles: string;
  address: string;
  phone: string;
  updated_at?: Date;
  bookings?: number;
  isadmin: number;
}

interface ColumnItem {
  name: string;
  sortOrder?: NzTableSortOrder;
  sortFn?: NzTableSortFn;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  users: User[];
  loading = true;

  columns: ColumnItem[] = [
    {
      name: 'Name',
      sortOrder: null,
      sortFn: (a: User, b: User) => a.name.localeCompare(b.name),
    },
    {
      name: 'Roles',
    },
    {
      name: 'Last login',
      sortOrder: null,
      sortFn: (a: User, b: User) => +new Date(a.updated_at) - +new Date(b.updated_at),
    },
    {
      name: 'Bookings',
      sortOrder: null,
      sortFn: (a: User, b: User) => a.bookings - b.bookings,
    },
    {
      name: 'Actions',
    },
  ];

  constructor(
    private router: Router, private nzMessageService: NzMessageService, private notification: NzNotificationService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.commonService.getUsers().subscribe((users: User[]) => {
      this.users = users;
      this.loading = false;
    });
  }

  addUser(): void {
    this.router.navigate(['/admin-edit/0']);
  }

  deleteUser(user: User): void {
    this.commonService.deleteUser(user).subscribe((resp) => {
      this.notification.create(
        'warning',
        'This user was deleted',
        ''
      );

      this.loadData();
    });
  }

  cancel(): void {
    //
  }

  dashboard(): void {
    this.router.navigate(['/admin-dashboard']);
  }
}
