<h2>{{ userId ? 'Change user' : 'Add user' }}</h2>

<nz-skeleton [nzActive]="true" *ngIf="loading"></nz-skeleton>

<form nz-form autocomplete="new-password" *ngIf="!loading">
    <nz-form-item>
      <nz-form-label [nzSpan]="4" nzFor="email">Username *</nz-form-label>
      <nz-form-control [nzSpan]="6" nzHasFeedback nzErrorTip="Please enter username">
        <input type="email" nz-input placeholder="Username" [(ngModel)]="currentUser.email" name="email" autocomplete="new-password" required />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzHasFeedback nzFor="password">Password *</nz-form-label>
        <nz-form-control [nzSpan]="6" nzErrorTip="Please enter password">
          <input required nz-input placeholder="Password" autocomplete="new-password" [(ngModel)]="currentUser.password" name="password" type="password" />
        </nz-form-control>
        <div style="color: #999"> &nbsp; <small>Empty to keep old password</small></div>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzFor="roles">Roles *</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="Please set roles" nzValidatingTip="Set roles">
            <nz-checkbox-group required [(ngModel)]="roles" name="roles" (ngModelChange)="setRoles($event)"></nz-checkbox-group>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzHasFeedback nzFor="name">Display Name *</nz-form-label>
        <nz-form-control [nzSpan]="6" nzErrorTip="Please enter display name">
          <input nz-input placeholder="Name" [(ngModel)]="currentUser.name" name="name" required />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzFor="address">E-Mail</nz-form-label>
        <nz-form-control [nzSpan]="6">
          <input nz-input placeholder="Address" [(ngModel)]="currentUser.address" name="address" />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzFor="phone">Phone</nz-form-label>
        <nz-form-control [nzSpan]="6">
          <input nz-input placeholder="Phone" [(ngModel)]="currentUser.phone" name="phone" />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="userId">
        <nz-form-label [nzSpan]="4">Last login</nz-form-label>
        <nz-form-control [nzSpan]="6">
          {{ currentUser.updated_at | date:'dd.MM.yyyy, HH:mm' }}
        </nz-form-control>
    </nz-form-item>

    <!-- nz-form-item *ngIf="userId">
        <nz-form-label [nzSpan]="4">Bookings</nz-form-label>
        <nz-form-control [nzSpan]="6">
          5
        </nz-form-control>
    </nz-form-item -->

    <br/>

    <div nz-row>
        <div nz-col [nzSpan]="11" style="text-align: right;">
            <button nz-button nzType="primary" (click)="addUser()">
                <i nz-icon [nzType]="'plus'" [nzTheme]="'outline'"></i> {{ this.userId ? 'Change user' : 'Add user' }}
            </button>
        </div>
        <div nz-col [nzSpan]="2">
        </div>
        <div nz-col [nzSpan]="11" style="text-align: left;">
            <button nz-button nzType="dashed" (click)="cancelUser()">
                Cancel
            </button>
        </div>
    </div>
</form>