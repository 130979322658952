 <div nz-row>
    <div nz-col [nzOffset]="6" [nzSpan]="12">
        
        <h1 style="text-align: center;">Sporting Park Engelberg</h1>
        <h3 style="text-align: center;">Tennisplatz Reservations-System</h3>

        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your username!">
                <nz-input-group nzPrefixIcon="user">
                  <input type="text" nz-input formControlName="userName" placeholder="Benutzername" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <nz-input-group nzPrefixIcon="lock">
                  <input type="password" nz-input formControlName="password" placeholder="Passwort" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            
            <!-- div nz-row class="login-form-margin">
              <div nz-col [nzSpan]="12">
                <label nz-checkbox formControlName="remember">
                  <span>Remember me</span>
                </label>
              </div>
              <div nz-col [nzSpan]="12">
                <a class="login-form-forgot">Forgot password</a>
              </div>
            </div -->
        
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Login</button>
        </form>
    </div>

    <div nz-col [nzOffset]="4" [nzSpan]="16">
      <div style="text-align: center;">
        Hier können Mitglieder des TC Engelberg Tennisplatzreservationen für Aussen- sowie Hallenplätze tätigen.<br/>
        Ein Zugang mit persönlichem Login kann von allen TC Mitgliedern per Mail info@sportingpark.ch beantragt werden.<br/>
        Bei Fragen oder Unklarheiten wenden Sie sich bitte an Herbert Kuster unter 041 639 60 00
      </div>
    </div>
</div>