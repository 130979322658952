import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_DATE_CONFIG, NZ_I18N, en_GB } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
import { registerLocaleData, DatePipe } from '@angular/common';
// import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { LoginComponent } from './components/login/login.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzListModule } from 'ng-zorro-antd/list';

registerLocaleData(en);

import {
  UserOutline,
  LockOutline,
  PlusOutline,
  ProfileOutline,
} from '@ant-design/icons-angular/icons';
import { BookingsComponent } from './components/bookings/bookings.component';
import {
  AdminDashboardComponent,
  ReslistPipe,
  DateobjPipe,
} from './components/admin-dashboard/admin-dashboard.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminEditComponent } from './components/admin-edit/admin-edit.component';
import { AdminBookingsComponent } from './components/admin-bookings/admin-bookings.component';
import { UserBookingsComponent } from './components/user-bookings/user-bookings.component';
import {
  RolePipe,
  UserEditComponent,
} from './components/user-edit/user-edit.component';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMessageService } from 'ng-zorro-antd/message';

import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { GraphComponent } from './components/graph/graph.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

const icons: IconDefinition[] = [
  UserOutline,
  LockOutline,
  PlusOutline,
  ProfileOutline,
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserDashboardComponent,
    BookingsComponent,
    AdminDashboardComponent,
    AdminUsersComponent,
    AdminEditComponent,
    AdminBookingsComponent,
    UserBookingsComponent,
    UserEditComponent,
    ReslistPipe,
    DateobjPipe,
    RolePipe,
    GraphComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzButtonModule,
    NzLayoutModule,
    NzGridModule,
    NzInputModule,
    NzTableModule,
    NzDividerModule,
    NzTimePickerModule,
    NzDatePickerModule,
    NzSelectModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzFormModule,
    NzSkeletonModule,
    NzRadioModule,
    NzListModule,
    NzTabsModule,
    ReactiveFormsModule,
    NzIconModule.forRoot(icons),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_GB },
    NzNotificationService,
    NzMessageService,
    DatePipe,
    DateobjPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
