import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { User } from '../admin-users/admin-users.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.css']
})
export class AdminEditComponent implements OnInit {

  userId: number;
  currentUser: User;
  loading = false;

  roles = [
    { label: 'Instructor', value: 'Instructor' },
    { label: 'Club Member', value: 'Club Member' },
    { label: 'Club Guest', value: 'Club Guest' },
    { label: 'Round Robin', value: 'Round Robin' }
  ];

  constructor(
    private route: ActivatedRoute, private router: Router, private commonService: CommonService,
    private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.userId = Number(params.get('userId'));

      if (this.userId) {
        this.loading = true;
        this.commonService.getUser(this.userId).subscribe((user: User) => {
          this.currentUser = user;
          this.loading = false;

          this.currentUser.roles.split(',').map((role) => {
            this.roles = this.roles.map(r => {
              if (r.value === role) {
                return {
                  ...r,
                  checked: true
                };
              }

              return r;
            });
          });
        });
      }
      else {
        this.currentUser = {
          id: null,
          name: '',
          email: '',
          password: '',
          roles: '',
          address: '',
          phone: '',
          isadmin: 0,
        };
      }
    });
  }

  addUser(): void {
    if (!this.currentUser.roles || !this.currentUser.name || !this.currentUser.email
        || (!this.currentUser.id && !this.currentUser.password)) {
      this.notification.create(
        'warning',
        'Error',
        'Please fill all required fields',
      );
    }
    else {
      if (this.currentUser.id) {
        this.commonService.updateUser(this.currentUser).subscribe((user: User) => {
          this.router.navigate(['/admin-users']);
        });
      }
      else {
        this.commonService.saveUser(this.currentUser).subscribe((user: User) => {
          this.router.navigate(['/admin-users']);
        },
        (err) => {
          this.notification.create(
            'warning',
            'User already exists',
            'This email is already in use',
          );
        });
      }
    }
  }

  cancelUser(): void {
    this.router.navigate(['/admin-users']);
  }

  setRoles(event: any[]): void {
    this.currentUser.roles = event.filter(e => e.checked).map(e => e.value).join(',');
  }
}
