<nz-layout class="layout">
  <nz-header>
    <div class="logo">
      <img src="assets/logo2.png" height="80">
    </div>
    <ul nz-menu nzTheme="dark" nzMode="horizontal">
      <li *ngIf="!isadmin && username" nz-menu-item><a [routerLink]="['user-dashboard']">User Dashboard</a></li>
      <li nz-menu-item *ngIf="isadmin"><a [routerLink]="['admin-dashboard']">Dashboard</a></li>
      <li nz-menu-item *ngIf="isadmin"><a [routerLink]="['']">Bookings</a></li>
      <li nz-menu-item *ngIf="isadmin"><a [routerLink]="['admin-users']">Users</a></li>

      <div *ngIf="username" class="logout-block">
        <nz-avatar [nzShape]="'square'" [nzSize]="'large'" [nzIcon]="'user'"></nz-avatar>
        <div style="line-height: 20px; margin-left: 10px; margin-top: 15px">
          {{username}}<br/>
          <a style="padding-left: 0" nz-button [nzSize]="'small'" [nzType]="'link'" (click)="logout()">Logout</a>
        </div>
      </div>
    </ul>
  </nz-header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>created by <a href="https://rits.ch/">rits.ch</a> &copy; {{ curDate | date:'yyyy' }}</nz-footer>
</nz-layout>