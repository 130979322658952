<h2 *ngIf="showGreet">Willkommen zurück, {{ currentUsername }}</h2>
<br/>

<div nz-row>
    <div nz-col [nzSpan]="12">
        <h4>Reservationen</h4>
    </div>
    <div nz-col [nzSpan]="12" style="text-align: right;">
        <button nz-button nzType="dashed" (click)="addReservation()">
            <i nz-icon [nzType]="'plus'" [nzTheme]="'outline'"></i> Neue Reservation
        </button>
    </div>
</div>

<br/>

<nz-skeleton [nzActive]="true" *ngIf="loadingUpcoming"></nz-skeleton>

<nz-table #upcomingTable [nzData]="upcomingEvents" *ngIf="!loadingUpcoming" [nzPageSize]="10" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th style="width: 10%">Datum</th>
        <th style="width: 16%">Zeit</th>
        <th style="width: 14%">Platz</th>
        <th style="width: 40%">Spieler</th>
        <th>Aktionen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of upcomingTable.data">
        <td>
            <span class="hide-mobile">{{data.DateStart.substring(8,10)}}.{{data.DateStart.substring(5,7)}}.{{data.DateStart.substring(0, 4)}}</span>
            <span class="hide-desk">{{data.DateStart.substring(8,10)}}.{{data.DateStart.substring(5,7)}}.</span>
        </td>
        <td>
            <span class="hide-mobile">{{data.DateStart.substring(11, 16)}} - {{data.DateEnd.substring(11, 16)}}</span>
            <span class="hide-desk">{{data.DateStart.substring(11, 16)}}-{{data.DateEnd.substring(11, 16)}}</span>
        </td>
        <td>
          <span class="hide-mobile">{{data.ResourceName.split('|')[0]}}</span>
          <span class="hide-desk">{{data.ResourceName.split('|')[0].replace('Tennisp', 'P')}}</span>
        </td>
        <td>{{ data.Title }}</td>
        <td>
          <a [routerLink]="['/user-edit', data.ID]">Ändern</a>
          <nz-divider class="hide-mobile" nzType="vertical"></nz-divider>
          <ng-container *ngIf="!(data.ResourceName.split('|')[0].indexOf('5') > -1) && !(data.ResourceName.split('|')[0].indexOf('6') > -1)">
            <span class="hide-desk"><br/></span>
            <a nz-popconfirm nzPopconfirmTitle="Diese Reservation definitiv löschen?" nzPopconfirmPlacement="bottom" (nzOnCancel)="cancel()" (nzOnConfirm)="deleteReservation(data)">Löschen</a>
          </ng-container>
        </td>
      </tr>
    </tbody>
</nz-table>

<br/>

<div nz-row>
    <div nz-col [nzSpan]="12">
        <h4>Letzte 10 Buchungen</h4>
    </div>
</div>

<nz-skeleton [nzActive]="true" *ngIf="loadingHistory"></nz-skeleton>

<nz-table #historyTable [nzData]="historyEvents" [nzPageSize]="10" [nzFrontPagination]="false" *ngIf="!loadingHistory">
    <thead>
      <tr>
        <th style="width: 10%">Datum</th>
        <th style="width: 16%">Zeit</th>
        <th style="width: 14%">Platz</th>
        <th style="width: 40%">Spieler</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of historyTable.data">
        <td>
            <span class="hide-mobile">{{data.DateStart.substring(8,10)}}.{{data.DateStart.substring(5,7)}}.{{data.DateStart.substring(0, 4)}}</span>
            <span class="hide-desk">{{data.DateStart.substring(8,10)}}.{{data.DateStart.substring(5,7)}}.</span>
        </td>
        <td>
            <span class="hide-mobile">{{data.DateStart.substring(11, 16)}} - {{data.DateEnd.substring(11, 16)}}</span>
            <span class="hide-desk">{{data.DateStart.substring(11, 16)}}-{{data.DateEnd.substring(11, 16)}}</span>
        </td>
        <td>
          <span class="hide-mobile">{{data.ResourceName.split('|')[0]}}</span>
          <span class="hide-desk">{{data.ResourceName.split('|')[0].replace('Tennisp', 'P')}}</span>
        </td>
        <td>
            {{ data.Title }}
        </td>
        <td></td>
      </tr>
    </tbody>
</nz-table>

<ng-template #customTpl>
  <div style="text-align: center;">
    <i nz-icon nzType="smile" style="font-size: 20px;"></i>
    <p>Keine Daten</p>
  </div>
</ng-template>