import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GuestGuardService } from './services/guest-guard.service';
import { BookingsComponent } from './components/bookings/bookings.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { AdminBookingsComponent } from './components/admin-bookings/admin-bookings.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminEditComponent } from './components/admin-edit/admin-edit.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [ GuestGuardService ] },
  { path: '', component: BookingsComponent, canActivate: [ AuthGuardService ] },
  { path: 'user-dashboard', component: UserDashboardComponent, canActivate: [ AuthGuardService ] },
  { path: 'user-edit/:reservationId', component: UserEditComponent, canActivate: [ AuthGuardService ] },
  { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [ AuthGuardService ] },
  { path: 'admin-edit/:userId', component: AdminEditComponent, canActivate: [ AuthGuardService ] },
  { path: 'admin-users', component: AdminUsersComponent, canActivate: [ AuthGuardService ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
