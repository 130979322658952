import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonService } from 'src/app/services/common.service';
import { BookedEvent } from '../user-edit/user-edit.component';
import { NzConfigService } from 'ng-zorro-antd/core/config';

export interface ScheduledEvent {
  id: number;
  name: string;
  start: Date;
  end: Date;
  resource_id: number;
  resource_name?: string;
  resource_group_id?: number;
  user_id: number;
  user_roles?: string;
  user_name?: string;
}

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDashboardComponent implements OnInit {

  @ViewChild('customTpl', { static: false }) customTpl?: TemplateRef<any>;

  currentUserId: number;
  currentUsername: string;
  upcomingEvents: BookedEvent[];
  historyEvents: BookedEvent[];
  loadingUpcoming = true;
  loadingHistory = true;
  showGreet = false;

  constructor(
    private commonService: CommonService, private router: Router, private nzMessageService: NzMessageService,
    private notification: NzNotificationService, private nzConfigService: NzConfigService
  ) { }

  ngOnInit(): void {
    this.nzConfigService.set('empty', { nzDefaultEmptyContent: this.customTpl });

    if (!localStorage.getItem('greet')) {
      localStorage.setItem('greet', '1');
      this.showGreet = true;
      setTimeout(() => this.showGreet = false, 5000);
    }

    this.commonService.currentUserId.subscribe(userId => this.currentUserId = userId);
    this.commonService.currentUser.subscribe(userName => this.currentUsername = userName);

    this.commonService.getUserHistoryEvents().subscribe((historyEvents: BookedEvent[]) => {
      this.loadData();

      this.historyEvents = historyEvents;
      this.loadingHistory = false;
    });
  }

  private loadData(): void {
    this.loadingUpcoming = true;

    this.commonService.getUserUpcomingEvents().subscribe((events: BookedEvent[]) => {
      this.upcomingEvents = events;

      this.loadingUpcoming = false;
    });
  }

  addReservation(): void {
    this.router.navigate(['user-edit/0']);
  }

  deleteReservation(event: BookedEvent): void {
    this.commonService.deleteEvent(event).subscribe((resp) => {
      this.notification.create(
        'warning',
        'This reservation was deleted',
        ''
      );

      this.loadData();
    });
  }

  cancel(): void {
    //
  }
}
