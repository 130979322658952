<form nz-form [formGroup]="form">
    <nz-form-item>
      <nz-form-label [nzSpan]="4" nzFor="reservationDate">Reservation date</nz-form-label>
      <!-- 
      <nz-form-control [nzSpan]="6">
        <nz-date-picker [(ngModel)]="reservationDate" name="reservationDate" (ngModelChange)="onChange($event)"></nz-date-picker>
      </nz-form-control>
      -->
      <nz-form-control [nzSpan]="12">
        <button (click)="dateAdd(-1)" nz-button nzType="default">
            <i nz-icon nzType="left" nzTheme="outline"></i>
        </button>
        
        &nbsp;
        
        <nz-date-picker formControlName="DateStart" (ngModelChange)="changeDate($event)"></nz-date-picker>

        &nbsp;

        <button (click)="dateAdd(1)" nz-button nzType="default">
            <i nz-icon nzType="right" nzTheme="outline"></i>
        </button>
      </nz-form-control>
    </nz-form-item >
</form>

<h4 *ngIf="events">Total bookings: {{ events.length }}</h4>

<nz-skeleton [nzActive]="true" *ngIf="loading"></nz-skeleton>

<app-graph *ngIf="!loading" [userView]="!isAdmin" [events]="events" [resources]="resources" [date]="form.get('DateStart').value"></app-graph>