<h2 *ngIf="showGreet">Welcome back, {{ currentUsername }}</h2>
<br/>

<nz-tabset>
  <nz-tab nzTitle="Today">
    <div nz-row style="margin-top: 10px">
      <div nz-col [nzSpan]="12">
          <h4>Upcoming reservations today</h4>
      </div>
    </div>
  
    <br/>
  
    <nz-skeleton [nzActive]="true" *ngIf="todayBookingsLoading"></nz-skeleton>
  
    <nz-table #todayTable [nzData]="todayBookings" [nzPageSize]="10" *ngIf="!todayBookingsLoading">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Court</th>
            <th>Players</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let data of todayTable.data">
            <td>{{data.DateStart | dateobj | date:'dd.MM.yyyy'}}</td>
            <td>
              {{data.DateStart | dateobj | date:'HH:mm'}} - {{data.DateEnd | dateobj | date:'HH:mm'}}
            </td>
            <td>
              {{ data.ResourceName | reslist }}
            </td>
            <td>
              {{ data.Title }}<br/>
              <small>{{data.SubText}}</small>
            </td>
            <td>
              <a [routerLink]="['/user-edit', data.ID]">Change</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-popconfirm nzPopconfirmTitle="Are you sure delete this reservation?" nzPopconfirmPlacement="bottom" (nzOnCancel)="cancel()" (nzOnConfirm)="deleteReservation(data)">Delete</a>
            </td>
          </tr>
        </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Upcoming">
    <div nz-row>
      <div nz-col [nzSpan]="12">
          <h4>Upcoming reservations tomorrow</h4>
      </div>
    </div>
    
    <br/>
    
    <nz-skeleton [nzActive]="true" *ngIf="tomorrowBookingsLoading"></nz-skeleton>
    
    <nz-table #tomorrowTable [nzData]="tomorrowBookings" [nzPageSize]="10" *ngIf="!tomorrowBookingsLoading">
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Court</th>
          <th>Players</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tomorrowTable.data">
          <td>{{data.DateStart | dateobj | date:'dd.MM.yyyy'}}</td>
          <td>
            {{data.DateStart | dateobj | date:'HH:mm'}} - {{data.DateEnd | dateobj | date:'HH:mm'}}
          </td>
          <td>
            {{ data.ResourceName | reslist }}
          </td>
          <td>
              {{ data.Title }}<br/>
              <small>{{data.SubText}}</small>
          </td>
          <td>
            <a *ngIf="data.SubText" [routerLink]="['/user-edit', data.ID]">Change</a>
            <nz-divider *ngIf="data.SubText" nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="Are you sure delete this reservation?" nzPopconfirmPlacement="bottom" (nzOnCancel)="cancel()" (nzOnConfirm)="deleteReservation(data)">Delete</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="History">
    <div nz-row>
      <div nz-col [nzSpan]="12">
          <h4>History reservations</h4>
      </div>
    </div>
    
    <br/>
    
    <nz-skeleton [nzActive]="true" *ngIf="historyLoading"></nz-skeleton>
    
    <nz-table #tomorrowTable [nzData]="historyBookings" [nzPageSize]="10" *ngIf="!historyLoading">
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Court</th>
          <th>Players</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tomorrowTable.data">
          <td>{{data.DateStart | dateobj | date:'dd.MM.yyyy'}}</td>
          <td>
            {{data.DateStart | dateobj | date:'HH:mm'}} - {{data.DateEnd | dateobj | date:'HH:mm'}}
          </td>
          <td>
            {{ data.ResourceName | reslist }}
          </td>
          <td>
              {{ data.Title }}<br/>
              <small>{{data.SubText}}</small>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>

