import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from 'src/app/services/jwt-helper.service';
import { CommonService } from 'src/app/services/common.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  validateForm: FormGroup;

  constructor(
    private authService: AuthService, private router: Router, private jwtHelper: JwtHelperService,
    private commonService: CommonService, private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.validateForm = new FormGroup({
      userName: new FormControl(),
      password: new FormControl(),
    });
  }

  submitForm(): void {
    this.authService.login(this.validateForm.controls.userName.value, this.validateForm.controls.password.value)
      .subscribe((res: any) => {
        localStorage.setItem('access_token', res.access_token);

        this.commonService.updateUsername(this.jwtHelper.name());
        this.commonService.updateUserId(this.jwtHelper.id());
        this.commonService.updateAdmin(this.jwtHelper.isadmin());
        this.commonService.updateLogin(this.jwtHelper.email());
        this.commonService.updateRoles(this.jwtHelper.roles());

        if (this.jwtHelper.isadmin()) {
          this.router.navigate(['/admin-dashboard']);
        }
        else {
          this.router.navigate(['/user-dashboard']);
        }
      }, (err: any) => {
        //this.loading = false;
        //this.errors = true;

        this.notification.create(
          'warning',
          'Please check your login and password',
          ''
        );
      });
  }

}
